import { render, staticRenderFns } from "./trainSinglePicture.vue?vue&type=template&id=2bd2a8c4&scoped=true&"
import script from "./trainSinglePicture.vue?vue&type=script&lang=js&"
export * from "./trainSinglePicture.vue?vue&type=script&lang=js&"
import style0 from "./trainSinglePicture.vue?vue&type=style&index=0&id=2bd2a8c4&prod&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2bd2a8c4",
  null
  
)

component.options.__file = "trainSinglePicture.vue"
export default component.exports